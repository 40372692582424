<template>
  <div class="map-container w-full">
    <GMapMap :center="center" :zoom="16" map-type-id="terrain" style="height: 300px;">
      <GMapCluster>
        <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true"
          @click="center = m.position" />
      </GMapCluster>

    </GMapMap>
  </div>
</template>

<script>
import { GMapMap, GMapCluster } from 'vue-google-maps-community-fork'

export default {
  data() {
    return {
      center: {
        lat: 9.971843,
        lng: -84.088076
      },
      markers: [
        {
          position: {
            lat: 9.971843,
            lng: -84.088076
          },
        },
      ],
    }
  },
  components: {
    GMapMap,
    GMapCluster,
  },
  methods: {
    toggleInfoWindow(context) {
      this.infoWIndowContext = context
      this.showInfo = true
    },
    infoClicked(context) {
      console.log(context)
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
}

.gmap-map {
  width: 100%;
  height: 100%;
}
</style>
