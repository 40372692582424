<template>

  <body>
    <Header title="Beneficios" subtitle="Beneficios de Aso K-9 Internacional" image="benefits-header" />
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div class="mt-5 mr-5" v-for="(benefit, index) in benefits" :key="'key_benefitpage_' + index">
        <div v-if="index % 2 == 1 && isDesktop" class="flex flex-col md:flex-row w-full">
          <div class="md:w-2/3">
            <div class="p-8 bg-gray-100 rounded-lg">
              <SectionTitle :title="benefit.title" />
              <div class="text-gray-700">
                <p class="mb-3 text-justify" v-for="(item, index) in benefit.items"
                  :key="'key_benefitpage_detail_' + index">
                  <strong>{{ item.title }}</strong> {{ item.content }}
                </p>
                <a v-if="benefit.link_url && benefit.link_label" :href="benefit.link_url"
                  class="inline-block mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out">
                  {{ benefit.link_label }}
                </a>
              </div>
            </div>
          </div>
          <div class="md:w-1/3">
            <div class="p-8">
              <img class="w-full h-100 object-cover rounded-lg" :src="getImageUrl(benefit)" :alt="benefit.title" />
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col md:flex-row w-full">
          <div class="md:w-1/3">
            <div class="p-8">
              <img class="w-full h-100 object-cover rounded-lg" :src="getImageUrl(benefit)" :alt="benefit.title" />
            </div>
          </div>
          <div class="md:w-2/3">
            <div class="p-8 bg-gray-100 rounded-lg">
              <SectionTitle :title="benefit.title" />
              <div class="text-gray-700">
                <p class="mb-3 text-justify" v-for="(item, index) in benefit.items"
                  :key="'key_benefitpage_detail_' + index">
                  <strong>{{ item.title }}</strong> {{ item.content }}
                </p>
                <a v-if="benefit.link_url && benefit.link_label" :href="benefit.link_url"
                  class="inline-block mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out">
                  {{ benefit.link_label }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </body>
</template>

<script>
import Header from '../components/Header.vue';
import axios from 'axios';
import { parseImageUrl } from '@/utils';
import SectionTitle from '../components/SectionTitle.vue';

export default {
  name: 'BenefitsScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
    SectionTitle,
  },
  data() {
    return {
      benefits: null,
      fetching: true,
      isModalActive: false,
      selected: null,
      isDesktop: window.innerWidth >= 768, // Define your breakpoint here
    };
  },
  mounted() {
    this.fetchBenefits();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth >= 768; // Update the breakpoint as needed
    },
    getImageUrl(person) {
      return parseImageUrl(person); // Call the imported function
    },
    showMore(benefit) {
      this.isModalActive = true;
      this.selected = benefit;
    },
    fetchBenefits() {
      this.fetching = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/benefits`)
        .then((response) => {
          this.benefits = response.data;
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-data {
  margin-top: 70px;
  margin-bottom: 50px;
}

/* Tailwind CSS classes */

/* Par */
.sub-body-par {
  height: 550px;
  padding: 50px 20px 20px 20px;
  background: #f1f2f4;
}

.sub-body-par-image {
  height: 550px;
  padding: 0px;
  background: #f1f2f4;
}

.title-par {
  font-size: 28px;
  line-height: 33px;
  color: #21284b;
}

.text-par {
  font-size: 14px;
  line-height: 20px;
  color: #4f5571;
  height: 400px;
  overflow-y: auto;
}

/* Impar */
.body-impar-left {
  padding: 0px;
  text-align: left;
  border-color: transparent;
  background-color: transparent;
}

.body-impar-right {
  padding: 0px;
  text-align: left;
  background-color: #f1f2f4;
}

.is-primary-impar {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #202288 !important;
  background-color: transparent;
  border-color: transparent;
}

.title-impar {
  font-size: 28px;
  line-height: 33px;
  color: #21284b;
}

.text-impar {
  font-size: 14px;
  line-height: 20px;
  color: #4f5571;
  height: 400px;
  overflow-y: auto;
}

.icon-img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -o-object-fit: cover;
  object-fit: cover;
}

/* Tailwind CSS classes */
</style>
