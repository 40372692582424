<template>
  <div>
    <Header title="Convenios comerciales" subtitle="Convenios comerciales de Aso K-9 Internacional"
      image="agreements-header" />
    <Agreements class="mt-10" />
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Agreements from '../components/Agreements.vue'
import axios from 'axios';

export default {
  name: 'AgreementsScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
    Agreements,
  },
  data() {
    return {
      agreements: null,
      fetching: true,
      isModalActive: false,
      selected: null,
      agreementsFiltered: null,
      filters: [],
      filtersActive: [],
    };
  },
  mounted() {
    this.fetchAgreements();
  },
  methods: {
    goTo(agreement) {
      this.$router.push({
        name: 'AgreementDetail',
        query: { id: agreement.id },
      });
    },
    activateFilter(filter) {
      filter.active = !filter.active;
      if (filter.active) {
        this.filtersActive.push(filter.id);
      } else {
        this.filtersActive = this.filtersActive.filter((o) => {
          return o !== filter.id;
        });
      }
      const filterParameter = this.filtersActive.join();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/agreements?categories=${filterParameter}&limit=100`
        )
        .then((response) => {
          this.agreementsFiltered = response.data;
        })
        .catch(() => { });
    },
    fetchAgreements() {
      this.fetching = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/agreementCategories`)
        .then((response) => {
          this.filters = response.data.map((o) => {
            return { ...o, active: false };
          });
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/agreements?limit=100`)
        .then((response) => {
          this.agreements = response.data;
          this.agreementsFiltered = this.agreements;
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
