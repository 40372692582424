<template>
  <div class="flex-row">
    <div class="grid grid-cols-2">
      <div class="flex-row">
        <SectionTitle :title="'Asistencia Médica'" />
        <div class="flex-column mx-auto px-4 h-200">
          <video src="@/assets/asistencia.mp4" controls />
        </div>
      </div>
      <div class="flex-row">
        <SectionTitle :title="'Beneficiarios'" />
        <div class="flex-column mx-auto px-4 h-200">
          <video src="@/assets/beneficiarios.mp4" controls />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/SectionTitle.vue';

export default {
  data() {
    return {
      center: {
        lat: 9.971843,
        lng: -84.088076
      },
      markers: [
        {
          position: {
            lat: 9.971843,
            lng: -84.088076
          },
        },
      ],
    }
  },
  components: {
    SectionTitle,
  },
  methods: {
    toggleInfoWindow(context) {
      this.infoWIndowContext = context
      this.showInfo = true
    },
    infoClicked(context) {
      console.log(context)
    }
  }
}
</script>

<style scoped>
.map-container {
  width: 100%;
}

.gmap-map {
  width: 100%;
  height: 100%;
}
</style>
