<template>
  <div class="main-container">
    <Header :title="title" :subtitle="subtitle" image="benefits-header" />
    <HomeBenefits class="mb-5" />
    <HomeAgreements class="mb-5 mt-10" />
  </div>
</template>

<script>
import HomeBenefits from '../components/HomeBenefits.vue';
import HomeAgreements from '../components/Agreements.vue';
import Header from '../components/Header.vue';

export default {
  name: 'HomeScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
    HomeBenefits,
    HomeAgreements,
  },
  mounted() {
    this.activeModal();
    this.fetching();
  },
  data() {
    return {
      title: 'Asociación Solidarista de Empleados de K-9 Internacional SA y Afines',
      subtitle: 'Página oficial de Aso K-9 Internacional',
      announcements: [],
      isModalActive: false,
    };
  },
  computed: {
    name() {
      return this.$store.getters.association.name;
    },
    description() {
      return this.$store.getters.association.description;
    },
  },
  methods: {
    fetching() {
      // this.announcements = this.$store.getters.association.announcements;
    },
    async activeModal() {
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(1500);
      if (this.announcements.length > 0) {
        this.isModalActive = true;
      }
    },
  },
};
</script>

<style scoped></style>
