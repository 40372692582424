// Application routes

import AboutUsScreen from "./screens/AboutUsScreen.vue";
import AffiliateScreen from "./screens/AffiliateScreen.vue";
import AgreementDetailsScreen from "./screens/AgreementDetailsScreen.vue";
import AgreementsScreen from "./screens/AgreementsScreen.vue";
import BecasScreen from "./screens/BecasScreen.vue";
import BenefitsScreen from "./screens/BenefitsScreen.vue";
import CatalogScreen from "./screens/CatalogScreen.vue";
import ContactScreen from "./screens/ContactScreen.vue";
import FAQScreen from "./screens/FAQScreen.vue";
import HelpRequestScreen from "./screens/HelpScreen.vue";
import HomeScreen from "./screens/HomeScreen.vue";
import PolizaScreen from "./screens/PolizaScreen.vue";

const routes = [
  {
    path: "/",
    component: HomeScreen,
    name: "Home",
  },
  {
    path: "/inicio",
    component: HomeScreen,
    name: "index",
  },
  {
    path: "/nosotros",
    component: AboutUsScreen,
    name: "AboutUs",
  },
  {
    path: "/beneficios",
    component: BenefitsScreen,
    name: "Benefits",
  },
  {
    path: "/afiliarse",
    component: AffiliateScreen,
    name: "Afiliarse",
  },
  {
    path: "/becas-educativas",
    component: BecasScreen,
    name: "Becas",
  },
  {
    path: "/convenios",
    component: AgreementsScreen,
    name: "Agreements",
  },
  {
    path: "/convenios/detalle",
    component: AgreementDetailsScreen,
    name: "AgreementDetails",
  },
  {
    path: "/preguntas",
    component: FAQScreen,
    name: "Preguntas",
  },
  {
    path: "/poliza-de-vida",
    component: PolizaScreen,
    name: "Póliza de Vida",
  },
  {
    path: "/contacto",
    component: ContactScreen,
    name: "Contacto",
  },
  {
    path: "/ayudas",
    component: HelpRequestScreen,
    name: "Ayudas",
  },
  {
    path: "/catalogos",
    component: CatalogScreen,
    name: "Catálogos",
  },
];

export default routes;
