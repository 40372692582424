<template>

  <body>
    <Header title="Preguntas Frecuentes" subtitle="Preguntas frecuentes realizadas por nuestros asociados"
      image="helps-header" />
    <div class="flex flex-col items-center w-full pl-2">
      <div class="flex items-center justify-between mb-6">
        <AppVideo />
      </div>
    </div>
  </body>
</template>

<script>
import Header from "@/components/Header.vue";
import AppVideo from "@/components/AppVideo.vue";

export default {
  name: "FAQScreen",
  components: {
    Header,
    AppVideo,
  },
};
</script>

<style scoped>
.lefted {
  text-align: left;
}
</style>
