<template>
    <div class="flex items-center justify-between mb-6"></div>
    <div class="flex items-center flex-col">
      <h3 class="text-2xl font-bold mb-2 text-left w-full">{{ title }}</h3>
      <div class="flex w-100" style="width: 100%;">
        <svg width="180" height="12">
          <line x1="0" y1="0" x2="45" y2="0" stroke="#EBB859" stroke-width="8" />
          <line x1="45" y1="0" x2="90" y2="0" stroke="#DA753D" stroke-width="8" />
          <line x1="90" y1="0" x2="135" y2="0" stroke="#A34AFE" stroke-width="8" />
          <line x1="135" y1="0" x2="180" y2="0" stroke="#50B2BF" stroke-width="8" />
        </svg>
      </div>
  </div>
</template>

<script>

export default {
  name: 'SectionTitle',
  props: {
    title: String,
  },
  components: {
  },
}
</script>

<style scoped>
</style>
