<template>
  <div v-if="agreement">
    <Header :title="agreement.title" subtitle="Convenio ASO K-9" />
    <AgreementDetails :agreement="agreement" />
  </div>

</template>

<script>

import Header from '../components/Header.vue';
import AgreementDetails from '../components/AgreementDetails.vue';
import axios from 'axios';

export default {
  name: 'AgreementDetailsScreeen',
  props: {
    msg: String,
  },
  components: {
    Header,
    AgreementDetails,
  },
  data() {
    return {
      agreement: null,
      fetching: false,
    };
  },
  mounted() {
    this.fetchAgreement();
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'Agreements',
      });
    },
    fetchAgreement() {
      this.fetching = true;
      if (this.$route.query.id !== null) {
        axios
          .get(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/agreement?id=${this.$route.query.id}`
          )
          .then((response) => {
            this.agreement = response.data;
          })
          .catch(() => { })
          .finally(() => {
            this.fetching = false;
          });
      }
    }
  },
};
</script>

<style scoped>
/* Add your custom styles here */


.sub-container {
  max-height: 550px;
  min-height: 550px;
  border: none !important;
}

.container-agreement {
  background: transparent !important;
  border: none;
}

.space-agreement {
  padding-top: 6rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.picture-header {
  border-radius: 8px;
  max-height: 100% !important;
  display: block !important;
  width: fit-content;
  max-width: 100% !important;
}

.card-info {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem !;
  height: 90% !important;
  top: 5% !important;
  display: block !important;
}

.card-info .card-img {
  background-color: transparent;
  border-color: transparent;
  height: 100% !important;
  display: block !important;
  width: auto;
  max-width: 100%;
}

.card-picture {
  border-color: transparent !important;
  background-color: transparent !important;
  display: flex !important;
  justify-content: center !important;
  height: 90%;
  top: 5%;
  padding-right: 2rem;
  padding-left: 2rem;
}

.draw-header {
  fill: #c2ac6c;
  height: 5px;
  width: 50px;
}

.header-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: black;
}

.header-content {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: black;
}

.btn-return {
  color: white !important;
  background-color: transparent !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
</style>
