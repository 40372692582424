<template>
  <div class="container mx-auto">
    <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Datos personales</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

      <!-- GovId -->
      <div class="flex items-center flex-col">
        <div class="flex flex-row w-full">
          <label class="block text-gray-700 text-left">Número de cédula</label>
        </div>
        <div class="flex flex-row w-full">
          <div class="relative flex-grow">
            <input v-model="form.gov_id" @input="cleanData()" @keyup.enter="doSearchEmployee()" type="number"
              :class="{ 'border-red-500': !isValidValue(form.gov_id) || !isValidGovId() }" placeholder="Número de cédula"
              required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
          </div>
          <button @click="doSearchEmployee()" type="button"
            :disabled="!isValidValue(form.gov_id) || !isValidGovId() || loadingEmployee"
            class="ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
            Buscar
          </button>
          <div v-if="loadingEmployee" class="absolute inset-y-0 right-0 flex items-center pr-3">
            <div class="spinner-border text-blue-500" role="status">
              <span class="sr-only">Cargando...</span>
            </div>
          </div>
        </div>
      </div>
      <!-- GovId -->

      <!-- Name -->
      <div class="flex items-center flex-col" v-if="form.employee_id">
        <div class="flex flex-row w-full">
          <label class="block text-gray-700 text-left">Nombre completo</label>
        </div>
        <div class="flex flex-row w-full">
          <div class="relative flex-grow">
            <input v-model="form.name" disabled :class="{ 'border-red-500': !isValidValue(form.name) }" required
              class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
          </div>
        </div>
      </div>
      <!-- Name -->
    </div>

    <div class="mt-2" v-if="form.employee_id">


      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">

        <!-- Code -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Gafete</label>
          <input v-model="form.code" :class="{ 'border-red-500': !isValidValue(form.code) }" required disabled
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Code -->

        <!-- Department Code-->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Bravo</label>
          <input v-model="form.department_code" :class="{ 'border-red-500': !isValidValue(form.department_code) }"
            required disabled class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Department Code -->

        <!-- Nombre Department-->
        <div class="flex flex-col w-full" style="grid-column: span 2;">
          <label class="block text-gray-700 text-left">Nombre del Bravo</label>
          <input v-model="form.department_name" :class="{ 'border-red-500': !isValidValue(form.department_name) }"
            required disabled class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Department Name -->
      </div>

      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">

        <!-- Civil State -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Estado Civil</label>
          <select v-model="form.civil_state_id" :class="{ 'border-red-500': !isValidValue(form.civil_state_id) }" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
            <option value="" disabled selected>Selecciona un género</option>
            <option v-for="civil_state in civilStates" :key="civil_state.id" :value="civil_state.id">{{ civil_state.name
            }}</option>
          </select>
        </div>
        <!-- End Civil State -->

        <!--  Sex -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Sexo</label>
          <select v-model="form.gender_id" :class="{ 'border-red-500': !isValidValue(form.gender_id) }" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
            <option value="" disabled selected>Selecciona un género</option>
            <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{ gender.name }}</option>
          </select>
        </div>
        <!-- End Sex -->

        <!-- Birthdate -->
        <div class="flex flex-col">
          <label class="block text-gray-700 text-left">Fecha de Nacimiento</label>
          <input v-model="form.birthdate" type="date" :class="{ 'border-red-500': !isValidValue(form.birthdate) }"
            :on-change="form.age = getYears(form.birthdate)" placeholder="Fecha de Nacimiento" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Birthdate -->

        <!-- Age -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Edad</label>
          <input v-model="form.age" type="number" :class="{ 'border-red-500': !isValidValue(form.age) }"
            placeholder="Edad" required disabled
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Age -->

        <!-- Nationality -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">País de Nacimiento</label>
          <select v-model="form.country_id" :class="{ 'border-red-500': !isValidValue(form.country_id) }" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
            <option value="" disabled selected>Selecciona un país</option>
            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
          </select>
        </div>
        <!-- End Nationality-->

        <!-- Number of Children -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Número de Hijos</label>
          <input v-model="form.number_of_children" :class="{ 'border-red-500': !isValidValue(form.number_of_children) }"
            placeholder="Número de Hijos" type="number" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Number of Children -->

        <!-- Role Position -->
        <div class="flex flex-col w-full" style="grid-column: span 2;">
          <label class="block text-gray-700 text-left">Puesto</label>
          <input v-model="form.department_role" :class="{ 'border-red-500': !isValidValue(form.department_role) }"
            placeholder="Puesto" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Role Position -->
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

        <!-- Phone -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Teléfono Casa</label>
          <input v-model="form.phone" :class="{ 'border-red-500': !isValidValue(form.phone) }" placeholder="Teléfono Casa"
            required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Phone -->

        <!-- Cell Phone -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Teléfono Celular</label>
          <input v-model="form.cell_phone" :class="{ 'border-red-500': !isValidValue(form.cell_phone) }"
            placeholder="Teléfono Celular" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Cell Phone -->

        <!-- Email -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Correo electrónico</label>
          <input v-model="form.email"
            :class="{ 'border-red-500': !isValidValue(form.email) || !isValidEmail(form.email) }"
            placeholder="Correo electrónico" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Email -->

        <!-- Confirm Email -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Confirmar Correo electrónico</label>
          <input v-model="form.email_confirmation"
            :class="{ 'border-red-500': !isValidValue(form.email_confirmation) || !isValidEmail(form.email) || form.email_confirmation != form.email}"
            placeholder="Confirme su correo electrónico" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Confirm Email -->

      </div>
      <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Dirección</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <!--  Province -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Provincia</label>
          <select v-model="form.province_id" :class="{ 'border-red-500': !isValidValue(form.canton_id) }" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" @change="loadCantons()">
            <option value="" disabled selected>Selecciona una provincia</option>
            <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
          </select>
        </div>
        <!--  End Province -->

        <!-- Canton -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Cantón</label>
          <select v-model="form.canton_id" :class="{ 'border-red-500': !isValidValue(form.canton_id) }" required
            :disabled="cantons.length == 0"
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" @change="loadDistricts()">
            <option value="" disabled selected>Selecciona un cantón</option>
            <option v-for="canton in cantons" :key="canton.id" :value="canton.id">{{ canton.name }}</option>
          </select>
        </div>
        <!-- End Canton -->

        <!-- District -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Distrito</label>
          <select v-model="form.district_id" :class="{ 'border-red-500': !isValidValue(form.district_id) }" required
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400"
            :disabled="districts.length == 0">
            <option value="" disabled selected>Selecciona un cantón</option>
            <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
          </select>
        </div>
        <!-- End District -->

      </div>

      <!-- Address Detail-->
      <div class="flex flex-col w-full mt-4">
        <label class="block text-gray-700 text-left">Dirección Detallada</label>
        <input v-model="form.address_detail" :class="{ 'border-red-500': !isValidValue(form.address_detail) }"
          placeholder="Dirección detallada" required
          class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
      </div>
      <!-- End Address Detail-->

      <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Ingreso del Grupo Familiar</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Family income -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Ingreso familiar por mes en colones</label>
          <input v-model="form.family_income" :class="{ 'border-red-500': !isValidValue(form.family_income) }"
            placeholder="Ingreso familiar por mes" required type="number"
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Family Income-->

        <!-- Family Members -->
        <div class="flex flex-col w-full">
          <label class="block text-gray-700 text-left">Total de miembros de la familia</label>
          <input v-model="form.family_members" :class="{ 'border-red-500': !isValidValue(form.family_members) }"
            placeholder="Total de miembros de la familia" required type="number"
            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
        </div>
        <!-- End Family Members -->
      </div>

      <!-- Help Addressed To -->
      <div class="flex flex-col w-full mt-4">
        <label class="block text-gray-700 text-left">Tipo de Ayuda</label>
        <div v-for="item in help_destined_to" :key="item.id" class="flex items-center">
          <input type="checkbox" :value="item.id" v-model="form.help_destined_to[item.id]" class="mr-2">
          <label class="text-gray-700">{{ item.value }}</label>
        </div>
      </div>
      <!-- Help Addressed To -->

      <!-- Detail -->
      <div class="flex flex-col w-full mt-4">
          <label class="block text-gray-700 text-left" for="detail">Descripción detallada</label>
          <textarea v-model="form.detail" id="detail" name="detail" placeholder="Cuentanos de forma detallada porqué necesitas la ayuda" rows="3"
            :class="{ 'border-red-500': !isValidValue(form.detail) }"
            class="form-textarea w-full px-4 py-3 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"></textarea>
      </div>
      <!-- Detail -->

      <div class="mt-4 text-left">
        <button @click="submitForm()" type="button"
          class="mt-4 ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
          Enviar información
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { CIVIL_STATES, COUNTRIES, GENDERS } from "../constants"

export default {
  name: "HelpRequestForm",
  data() {
    return {
      help_destined_to: [
        {
          id: 1,
          value: "Incapacidad por enfermedad",
        }, {
          id: 2,
          value: "Ayuda por enfermedad asociado o familiar",
        }, {
          id: 3,
          value: "Ayuda por afectación por catástrofe"
        }
      ],
      provinces: [
        { id: 1, name: "San José" },
        { id: 2, name: "Alajuela" },
        { id: 3, name: "Cartago" },
        { id: 4, name: "Heredia" },
        { id: 5, name: "Guanacaste" },
        { id: 6, name: "Puntarenas" },
        { id: 7, name: "Limón" },
      ],
      cantons: [],
      districts: [],
      genders: GENDERS,
      civilStates: CIVIL_STATES,
      countries: COUNTRIES,
      form: {
        gov_id: "",
        employee_id: null,
        department_name: null,
        department_code: null,
        code: "",
        name: "",
        last_name: "",
        phone: "",
        cell_phone: "",
        email: "",
        email_confirmation: "",
        province_id: null,
        canton_id: null,
        district_id: null,
        address_detail: "",
        family_members: null,
        family_income: null,
        number_of_children: null,
        department_role: null,
        help_destined_to: {},
        detail: null,
      },
      loadingEmployee: false,
      loadedEmployee: false,
    };
  },
  methods: {
    resetForm() {
      this.form = {
        gov_id: "",
        employee_id: null,
        department_name: null,
        department_code: null,
        code: "",
        name: "",
        last_name: "",
        phone: "",
        cell_phone: "",
        email: "",
        email_confirmation: "",
        province_id: null,
        canton_id: null,
        district_id: null,
        address_detail: "",
        family_members: null,
        family_income: null,
        number_of_children: null,
        department_role: null,
        help_destined_to: {},
        detail: null,
      };
      this.cantons = [];
      this.districts = [];
    },
    submitForm() {
      // Extract values from the array based on the keys in the object
      const selectedValues = [];
      for (const key in this.help_destined_to) {
          if (this.help_destined_to[key]) { // Check if the value is true
              const matchingItem = this.help_destined_to.find(item => item.id === parseInt(key));
              if (matchingItem) {
                  selectedValues.push(matchingItem.value);
              }
          }
      }

      // Concatenate the selected values into a string
      const resultString = selectedValues.join(", ");
      const finalForm = {
        ...this.form,
        help_destined_to: resultString,
      };
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/helpForm`,
          finalForm,
        )
        .then(() => {
          this.resetForm();
          alert("Información guardada exitosamente", "¡Gracias por actualizar sus datos!")
        })
        .catch((err) => {
          console.log(err.response)
          const detail = err.response
            ? err.response.data
            : "Por favor intente de nuevo";
          alert(detail)
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCantons() {
      this.cantons = [];
      this.districts = [];
      this.form.canton_id = null;
      this.form.district_id = null;
      const province = this.form.province_id;
      if (province) {
        this.loadingCantons = true;
        axios
          .get(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/cantons?provinceId=${province}`
          )
          .then((response) => {
            const results = response.data;
            if (results) {
              this.cantons = results;
              this.districts = [];
              this.form.canton_id = null;
              this.form.district_id = null;
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.loadingCantons = false;
          });
      }
    },
    loadDistricts() {
      const province = this.form.province_id;
      const canton = this.form.canton_id;
      if (province && canton) {
        this.loadingDistricts = true;
        axios
          .get(
            `${process.env.VUE_APP_SERVER_ENDPOINT}/districts?provinceId=${province}&cantonId=${canton}`
          )
          .then((response) => {
            let results = response.data;
            if (results) {
              this.districts = results;
              this.form.district_id = null;
            }
          })
          .catch(() => {
            this.form.name = "- -";
          })
          .finally(() => {
            this.loadingDistricts = false;
          });
      }
    },
    isValidValue(value) {
      if (value === null || value === undefined) {
        return false;
      }
      return value !== "";
    },
    isValidGovId() {
      const govId = `${this.form.gov_id}`;
      const amount = govId ? govId.length : 0;
      return amount > 8;
    },
    isValidEmail(email) {
      if (email != "") {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email) ? true : false;
      }
      return false;
    },
    isValidPhone(phone) {
      if (phone != "") {
        var re = /^\d{4}-\d{4}$/;
        var res = re.test(phone);
        return res ? true : false;
      }
      return false;
    },
    getYears(dateString) {
      // Returns the age based on the birthdate
      const birthDate = new Date(dateString);

      // Get the current date
      const currentDate = new Date();

      // Calculate the difference in years between the current date and the birth date
      let age = currentDate.getFullYear() - birthDate.getFullYear();

      // Check if the birthdate for this year has not occurred yet
      // and subtract 1 from the age if that's the case
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;

    },
    cleanData() {
      // Clean data logic if needed
    },
    handleError(error) {
      const errorDetail = error.response ? error.response.data : "Por favor intente más tarde";
      alert("Ha ocurrido un error", errorDetail)
    },
    noEmployeeLoaded() {
      this.form.name = "- -";
      this.form.code = "- -";
      this.form.employee_id = null;
      alert("Empleado no encontrado", "No ha sido posible encontrar la cédula ingresada");
      // this.showModal();
    },
    loadEmployeeData(data) {
      this.form.first_name = data.name;
      this.form.last_name = data.lname;
      this.form.sur_name = data.sname;
      this.form.name = `${data.name} ${data.lname} ${data.sname}`;
      this.form.code = data.code;
      this.form.employee_id = data.id;
      this.form.associated = data.association;
      this.form.gender_id = data.genderId;
      this.form.country_id = data.countryId;
      this.form.civil_state_id = data.civilStateId;
      this.form.email = data.email;
      const { department } = data;
      this.form.department_code = department.code;
      this.form.department_name = department.name;
      this.form.birthdate = data.birthdate;
    },
    doSearchEmployee() {
      this.loadingEmployee = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/employee?govId=${this.form.gov_id}`
        )
        .then((response) => {
          const data = response.data;
          if (data) {
            if (data.association) {
              this.loadEmployeeData(data);
            } else {
              alert("Empleado no asociado", "El empleado no se encuentra asociado a ASO K-9")
            }
          } else {
            console.log('here?')
            this.noEmployeeLoaded();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.loadingEmployee = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
