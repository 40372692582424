<template>
    <div class="container mx-auto">
        <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Datos personales</h3>

        <!-- Gov Id and name -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

            <!-- GovId -->
            <div class="flex items-center flex-col">
                <div class="flex flex-row w-full">
                    <label class="block text-gray-700 text-left">Número de cédula</label>
                </div>
                <div class="flex flex-row w-full">
                    <div class="relative flex-grow">
                        <input v-model="form.gov_id" @input="cleanData()" @keyup.enter="doSearchEmployee()" type="number"
                            :class="{ 'border-red-500': !isValidValue(form.gov_id) || !isValidGovId() }"
                            placeholder="Número de cédula" required
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                    <button @click="doSearchEmployee()" type="button"
                        :disabled="!isValidValue(form.gov_id) || !isValidGovId() || loadingEmployee"
                        class="ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
                        Buscar
                    </button>
                    <div v-if="loadingEmployee" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <div class="spinner-border text-blue-500" role="status">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- GovId -->

            <!-- Name -->
            <div class="flex items-center flex-col" v-if="form.employee_id">
                <div class="flex flex-row w-full">
                    <label class="block text-gray-700 text-left">Nombre completo</label>
                </div>
                <div class="flex flex-row w-full">
                    <div class="relative flex-grow">
                        <input v-model="form.name" disabled :class="{ 'border-red-500': !isValidValue(form.name) }" required
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                </div>
            </div>
            <!-- Name -->
        </div>
        <!-- Gov Id and name -->

        <div class="mt-2" v-if="form.employee_id">

            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">

                <!-- Code -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Gafete</label>
                    <input v-model="form.code" :class="{ 'border-red-500': !isValidValue(form.code) }" required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Code -->

                <!-- Birthdate -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Fecha de nacimiento</label>
                    <input v-model="form.birthdate" :class="{ 'border-red-500': !isValidValue(form.birthdate) }" required
                        disabled class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Birthdate -->

                <!-- Cell Phone -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Teléfono Celular</label>
                    <input v-model="form.cell_phone" :class="{ 'border-red-500': !isValidValue(form.cell_phone) }"
                        placeholder="Teléfono Celular" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Cell Phone -->

                <!-- Phone -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Teléfono Casa</label>
                    <input v-model="form.phone" placeholder="Teléfono Casa"
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Phone -->

                <!-- Email -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Correo electrónico</label>
                    <input v-model="form.email"
                        :class="{ 'border-red-500': !isValidValue(form.email) || !isValidEmail(form.email) }"
                        placeholder="Correo electrónico" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Email -->

                <!-- Confirm Email -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Confirmar Correo electrónico</label>
                    <input v-model="form.email_confirmation"
                        :class="{ 'border-red-500': !isValidValue(form.email_confirmation) || !isValidEmail(form.email) || form.email_confirmation != form.email }"
                        placeholder="Confirme su correo electrónico" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Confirm Email -->

                <!-- Civil State -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Estado Civil</label>
                    <select v-model="form.civil_state_id" :class="{ 'border-red-500': !isValidValue(form.civil_state_id) }"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                        <option value="" disabled selected>Selecciona un género</option>
                        <option v-for="civil_state in civilStates" :key="civil_state.id" :value="civil_state.id">{{
                            civil_state.name
                        }}</option>
                    </select>
                </div>
                <!-- End Civil State -->

                <!-- Number of Children -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Número de Hijos</label>
                    <input v-model="form.children" :class="{ 'border-red-500': !isValidValue(form.children) }"
                        placeholder="Número de Hijos" type="number" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Number of Children -->

            </div>

            <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">

                <!-- Company -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Empresa</label>
                    <input v-model="form.company_name" :class="{ 'border-red-500': !isValidValue(form.company_name) }"
                        required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Company -->

                <!-- Department Code-->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Bravo</label>
                    <input v-model="form.department_code" :class="{ 'border-red-500': !isValidValue(form.department_code) }"
                        required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Department Code -->

                <!-- Role Position -->
                <div class="flex flex-col w-full" style="grid-column: span 2;">
                    <label class="block text-gray-700 text-left">Puesto</label>
                    <input v-model="form.position_name" placeholder="Puesto"
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Role Position -->
            </div>


            <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Dirección</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <!--  Province -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Provincia</label>
                    <select v-model="form.province_id" :class="{ 'border-red-500': !isValidValue(form.canton_id) }" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400"
                        @change="loadCantons()">
                        <option value="" disabled selected>Selecciona una provincia</option>
                        <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                        </option>
                    </select>
                </div>
                <!--  End Province -->

                <!-- Canton -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Cantón</label>
                    <select v-model="form.canton_id" :class="{ 'border-red-500': !isValidValue(form.canton_id) }" required
                        :disabled="cantons.length == 0"
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400"
                        @change="loadDistricts()">
                        <option value="" disabled selected>Selecciona un cantón</option>
                        <option v-for="canton in cantons" :key="canton.id" :value="canton.id">{{ canton.name }}</option>
                    </select>
                </div>
                <!-- End Canton -->

                <!-- District -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Distrito</label>
                    <select v-model="form.district_id" :class="{ 'border-red-500': !isValidValue(form.district_id) }"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400"
                        :disabled="districts.length == 0">
                        <option value="" disabled selected>Selecciona un cantón</option>
                        <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                        </option>
                    </select>
                </div>
                <!-- End District -->

            </div>

            <!-- Address Detail-->
            <div class="flex flex-col w-full mt-4">
                <label class="block text-gray-700 text-left">Dirección Detallada</label>
                <input v-model="form.address_detail" :class="{ 'border-red-500': !isValidValue(form.address_detail) }"
                    placeholder="Dirección detallada" required
                    class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
            </div>
            <!-- End Address Detail-->

            <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Ahorros voluntarios</h3>
            <!-- Help Addressed To -->
            <div class="flex flex-col w-full mt-4">
                <div class="flex flex-wrap">
                    <div v-for="(item, index) in form.savings" :key="item.id" class="flex items-center w-1/2">
                        <input type="checkbox" :value="item.id" v-model="form.savings[index].checked" class="mr-2">
                        <label class="text-gray-700">{{ item.name }}</label>
                        <input v-model="form.savings[index].amount" type="number" placeholder="Monto" min="0" step="1"
                            v-if="form.savings[index].checked"
                            class="w-1/2 ml-4 px-3 rounded-md py-2 focus:outline-none focus:border-blue-400 border" />
                    </div>
                </div>
                <!-- Help Addressed To -->
            </div>

            <!-- Alert -->
            <div class="flex flex-col w-full mt-4">
                <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                    <div class="flex">
                        <div class="py-1">
                            <svg class="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path
                                    d="M10 0c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-.5-6.5h1v-5h-1v5zm0 2h1v-1h-1v1z" />
                            </svg>
                        </div>
                        <div>
                            <p class="font-bold" style="text-align: left;">Atención:</p>
                            <p class="text-sm" style="text-align: left">Solicito por este medio ser admitido como miembro de
                                esta asociación, declarando conocer y
                                aceptar las disposiciones de la
                                Junta Directiva, los Estatutos y sus Reglamentos. Además autorizo al Departamento de
                                Planillas de K-9 INTERNACIONAL, COPS,
                                ABC MONITOREO, ELECTRONIC SYSTEM, Y KLEAN K9, para que en lo sucesivo realice la retención
                                del porcentaje de ahorro Obrero,
                                correspondiente al 3% de mi salario, la retención de la póliza de vida, y el monto acordado
                                de Donación Asok-9.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Alert -->

            <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Beneficiarios</h3>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">

                <!-- Beneficiary 1 -->
                <div clas="flex items-center flex-col w-full">
                    <h4 class="text-lg font-bold mb-2 text-left">Beneficiario 1</h4>

                    <!-- Gov Id and name -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                        <!-- Parentesco -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-col w-full">
                                <label class="block text-gray-700 text-left">Parentesco</label>
                                <select v-model="form.beneficiary1.relationship"
                                    :class="{ 'border-red-500': !isValidValue(form.beneficiary1.relationship) }" required
                                    class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                                    <option value="" disabled selected>Selecciona un parentesco</option>
                                    <option v-for="relationship in relationships" :key="relationship.id"
                                        :value="relationship.name">{{ relationship.name }}</option>
                                </select>
                            </div>
                        </div>

                        <!-- Parentesco -->

                        <!-- Porcentaje -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Porcentaje</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary1.pctg" type="number" placeholder="Porcentaje"
                                        :class="{ 'border-red-500': !isValidValue(form.beneficiary1.pctg) }" required
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Porcentaje -->

                        <!-- Name -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Nombre completo</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary1.name" placeholder="Nombre completo"
                                        :class="{ 'border-red-500': !isValidValue(form.beneficiary1.name) }" required
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Name -->

                        <!-- GovId -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Número de cédula</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary1.gov_id" type="number"
                                        :class="{ 'border-red-500': !isValidValue(form.beneficiary1.gov_id) || !isValidGovId() }"
                                        placeholder="Número de cédula" required
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- GovId -->

                        <!-- Phone -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Número telefónico</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary1.phone" type="number" placeholder="Número telefónico"
                                        required
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Phone -->

                        <!-- Cellphone -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Otro número</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary1.cell_phone" type="number" placeholder="Otro número"
                                        required
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Cellphone -->

                    </div>

                    <!-- Address Detail-->
                    <div class="flex flex-col w-full mt-2">
                        <label class="block text-gray-700 text-left">Dirección Detallada</label>
                        <input v-model="form.beneficiary1.address_detail"
                            :class="{ 'border-red-500': !isValidValue(form.beneficiary1.address_detail) }"
                            placeholder="Dirección detallada" required
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                    <!-- End Address Detail-->

                </div>
                <!-- Beneficiary 1 -->

                <!-- Beneficiary 2 -->
                <div clas="flex items-center flex-col w-full">
                    <h4 class="text-lg font-bold mb-2 text-left">Beneficiario 2</h4>

                    <!-- Gov Id and name -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                        <!-- Parentesco -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-col w-full">
                                <label class="block text-gray-700 text-left">Parentesco</label>
                                <select v-model="form.beneficiary2.relationship"
                                    class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                                    <option value="" disabled selected>Selecciona un parentesco</option>
                                    <option v-for="relationship in relationships" :key="relationship.id"
                                        :value="relationship.id">{{ relationship.name }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Parentesco -->

                        <!-- Porcentaje -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Porcentaje</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary2.pctg" type="number" placeholder="Porcentaje"
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Porcentaje -->

                        <!-- Name -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Nombre completo</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary2.name" placeholder="Nombre completo"
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Name -->

                        <!-- GovId -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Número de cédula</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary2.gov_id" type="number" placeholder="Número de cédula"
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- GovId -->

                        <!-- Phone -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Número telefónico</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary2.phone" type="number" placeholder="Número telefónico"
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Phone -->

                        <!-- Cellphone -->
                        <div class="flex items-center flex-col">
                            <div class="flex flex-row w-full">
                                <label class="block text-gray-700 text-left">Otro número</label>
                            </div>
                            <div class="flex flex-row w-full">
                                <div class="relative flex-grow">
                                    <input v-model="form.beneficiary2.cell_phone" type="number" placeholder="Otro número"
                                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                                </div>
                            </div>
                        </div>
                        <!-- Cellphone -->

                    </div>

                    <!-- Address Detail-->
                    <div class="flex flex-col w-full mt-2">
                        <label class="block text-gray-700 text-left">Dirección Detallada</label>
                        <input v-model="form.beneficiary2.address_detail" placeholder="Dirección detallada"
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                    <!-- End Address Detail-->

                </div>
                <!-- Beneficiary 2 -->

            </div>

            <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">ASO K-9</h3>

            <div class="flex items-center flex-col">
                <div class="flex flex-row w-full">
                    <label class="block text-gray-700 text-left">Referido por:</label>
                </div>
                <div class="flex flex-row w-full">
                    <div class="relative flex-grow">
                        <input v-model="form.referred_by" placeholder="Compañero que me refirió"
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                </div>
            </div>


            <div class="mt-4 text-left">
                <button @click="submitForm()" type="button" :disabled="!isValidForm()"
                    class="mt-4 ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
                    Enviar información
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { CIVIL_STATES, COUNTRIES, GENDERS, RELATIONSHIPS } from "../constants"

export default {
    name: "AffiliateForm",
    data() {
        return {
            form: {
                gov_id: "",
                employee_id: null,
                department_name: null,
                department_code: null,
                company_name: null,
                position_name: "",
                children: "",
                code: "",
                name: "",
                phone: "",
                address_detail: "",
                referred_by: "",
                savings: [
                    {
                        id: 1,
                        name: "Navideño",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 2,
                        name: "Escolar",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 3,
                        name: "Extraordinario",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 4,
                        name: "Marchamo",
                        checked: false,
                        amount: 0,
                    }
                ],
                beneficiary1: {
                    name: "",
                    gov_id: "",
                    percentage: 0,
                    relationship: "",
                    phone: "",
                    cell_phone: "",
                    address_detail: "",
                },
                beneficiary2: {
                    name: "",
                    gov_id: "",
                    percentage: 0,
                    relationship: "",
                    phone: "",
                    cell_phone: "",
                    address_detail: "",
                },
            },
            provinces: [
                { id: 1, name: "San José" },
                { id: 2, name: "Alajuela" },
                { id: 3, name: "Cartago" },
                { id: 4, name: "Heredia" },
                { id: 5, name: "Guanacaste" },
                { id: 6, name: "Puntarenas" },
                { id: 7, name: "Limón" },
            ],
            cantons: [],
            districts: [],
            genders: GENDERS,
            civilStates: CIVIL_STATES,
            countries: COUNTRIES,
            relationships: RELATIONSHIPS,
            loadingEmployee: false,
            loadedEmployee: false,
        };
    },
    methods: {
        isValidForm() {
            const unrequiredFields = ["detail", "referred_by", "phone", "position_name", "beneficiary1", "beneficiary2"];
            for (const key in this.form) {
                if (unrequiredFields.includes(key)) {
                    continue;
                }
                if (!this.isValidValue(this.form[key])) {
                    return false;
                }
            }
            return true;
        },
        handleError(error) {
            const errorDetail = error.response ? error.response.data : "Por favor intente más tarde";
            alert("Ha ocurrido un error", errorDetail)
        },
        resetForm() {
            this.form = {
                gov_id: "",
                employee_id: null,
                department_name: null,
                department_code: null,
                company_name: null,
                birthdate: null,
                children: "",
                position_name: "",
                code: "",
                name: "",
                first_name: "",
                last_name: "",
                address_detail: "",
                plate: "",
                phone: "",
                detail: "",
                savings: [
                    {
                        id: 1,
                        name: "Navideño",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 2,
                        name: "Escolar",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 3,
                        name: "Extraordinario",
                        checked: false,
                        amount: 0,
                    }, {
                        id: 4,
                        name: "Marchamo",
                        checked: false,
                        amount: 0,
                    }
                ],
                beneficiary1: {
                    name: "",
                    gov_id: "",
                    relationship: "",
                    percentage: 0,
                    phone: "",
                    cell_phone: "",
                    address_detail: "",
                },
                beneficiary2: {
                    name: "",
                    gov_id: "",
                    relationship: "",
                    percentage: 0,
                    phone: "",
                    cell_phone: "",
                    address_detail: "",
                },
            }
        },
        isValidValue(value) {
            if (value === null || value === undefined) {
                return false;
            }
            return value !== "";
        },
        isValidGovId() {
            const govId = `${this.form.gov_id}`;
            const amount = govId ? govId.length : 0;
            return amount > 8;
        },
        isValidEmail(email) {
            if (email != "") {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email) ? true : false;
            }
            return false;
        },
        submitForm() {
            this.loading = true;
            const formData = { ... this.form };
            const beneficiaries = [formData.beneficiary1, formData.beneficiary2];
            formData['beneficiaries'] = beneficiaries;
            axios
                .post(`${process.env.VUE_APP_SERVER_ENDPOINT}/associateRequest`, formData)
                .then(() => {
                    this.resetForm();
                    alert("Información guardada exitosamente", "Estimado colaborador. Gracias por completar el formulario. En la brevedar posible nos pondremos en contacto con usted.");
                })
                .catch((err) => {
                    console.log(err.response);
                    const detail = err.response ? err.response.data : "Por favor intente de nuevo";
                    alert(detail);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cleanData() {
            // Clean data logic if needed
        },
        loadEmployeeData(data) {
            this.form.first_name = data.name;
            this.form.last_name = data.lname;
            this.form.sur_name = data.sname;
            this.form.name = `${data.name} ${data.lname} ${data.sname}`;
            this.form.code = data.code;
            this.form.employee_id = data.id;
            this.form.associated = data.association;
            this.form.proprietary_email = data.email;
            this.form.phone = data.phone;
            this.form.cell_phone = data.phone;
            if (data.cell_phone) {
                this.form.cell_phone = data.cell_phone;
            }
            this.form.birthdate = data.birthdate;
            const { department } = data;
            this.form.department_code = department.code;
            this.form.department_name = department.name;
            this.form.company_name = data.company.name;
        },
        loadCantons() {
            this.cantons = [];
            this.districts = [];
            this.form.canton_id = null;
            this.form.district_id = null;
            const province = this.form.province_id;
            if (province) {
                this.loadingCantons = true;
                axios
                    .get(
                        `${process.env.VUE_APP_SERVER_ENDPOINT}/cantons?provinceId=${province}`
                    )
                    .then((response) => {
                        const results = response.data;
                        if (results) {
                            this.cantons = results;
                            this.districts = [];
                            this.form.canton_id = null;
                            this.form.district_id = null;
                        }
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        this.loadingCantons = false;
                    });
            }
        },
        loadDistricts() {
            const province = this.form.province_id;
            const canton = this.form.canton_id;
            if (province && canton) {
                this.loadingDistricts = true;
                axios
                    .get(
                        `${process.env.VUE_APP_SERVER_ENDPOINT}/districts?provinceId=${province}&cantonId=${canton}`
                    )
                    .then((response) => {
                        let results = response.data;
                        if (results) {
                            this.districts = results;
                            this.form.district_id = null;
                        }
                    })
                    .catch(() => {
                        this.form.name = "- -";
                    })
                    .finally(() => {
                        this.loadingDistricts = false;
                    });
            }
        },
        doSearchEmployee() {
            this.loadingEmployee = true;
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_ENDPOINT}/employee?govId=${this.form.gov_id}`
                )
                .then((response) => {
                    const data = response.data;
                    if (data) {
                        if (data.association) {
                            this.loadEmployeeData(data);
                        } else {
                            alert("Empleado no asociado", "El empleado no se encuentra asociado a ASO K-9")
                        }
                    } else {
                        console.log('here?')
                        this.noEmployeeLoaded();
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // this.handleError(error);
                })
                .finally(() => {
                    this.loadingEmployee = false;
                });
        },
    },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
