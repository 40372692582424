<template>

  <body>
    <Header title="Poliza de Vida" subtitle="Información de las pólizas de Vida" image="helps-header" />
    <div class="flex flex-col items-center w-full pl-2">
      <div class="flex items-center justify-between mb-6">
        <PolizaVideos />
      </div>
    </div>
  </body>
</template>

<script>
import Header from "@/components/Header.vue";
import PolizaVideos from "@/components/PolizaVideos.vue";

export default {
  name: "PolizaScreen",
  components: {
    Header,
    PolizaVideos,
  },
};
</script>

<style scoped>
.lefted {
  text-align: left;
}
</style>
