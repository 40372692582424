<template>
  <section class="bg-gradient-to-r from-orange-500 via-pink-500 to-purple-500 py-10">
    <div class="container mx-auto px-4">
      <div class="text-center">
        <h1 class="text-4xl font-bold text-white mb-4">
          {{ title }}
        </h1>
        <h2 class="text-2xl text-white">
          {{ subtitle }}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HeaderComponent',
  props: {
    title: String,
    subtitle: String,
  },
  components: {
  },
}
</script>

<style scoped>
.hero-body {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.title,
.subtitle {
  text-align: center;
}

.hr {
  background-color: #E6CE9E;
  margin: 0;
  height: 5px;
}
</style>
