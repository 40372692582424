<template>
    <div class="flex flex-wrap container-card">
        <div class="w-full lg:w-1/4 p-4" v-for="(person, index) in staff" :key="'key_staff_card_' + index">
          <AdminCard :title="person.name" :subtitle="person.position.name" :email="person.email"
            :image="getImageUrl(person)" />
        </div>
      </div>
</template>

<script>
import AdminCard from '../components/AdminCard.vue';
import { parseImageUrl } from '@/utils';
import axios from 'axios';

export default {
  name: 'AdminStaff',
  components: {
    AdminCard,
  },
  props: {
    directive: Boolean,
  },
  data() {
    return {
      fetching: true,
      staff: [],
    };
  },
  mounted() {
    this.fetchStaff();
  },
  methods: {
    getImageUrl(person) {
      return parseImageUrl(person); // Call the imported function
    },
    fetchStaff() {
      this.fetching = true;
      const directive = (this.directive) ? true : false;
      console.log('Prop is', this.directive)
      console.log('Directive is', directive)
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/administratives?directive=${directive}`
        )
        .then((response) => {
          this.staff= response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
