<template>
  <div>
    <div class="flex flex-col items-center">
      <div class="w-full md:w-3/4 xl:w-3/4">
        <SectionTitle :title="'Nuestros beneficios'" />
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div v-for="(item, index) in benefits" :key="'benefit_item_' + index" class="bg-white rounded-md shadow p-6">
            <div class="text-lg font-bold mb-2">{{ item.title }}</div>
            <img :src="getImageUrl(item)" :alt="item.title" class="h-40 w-full object-contain mb-4">
            <div class="text-sm">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed inset-0 flex items-center justify-center z-50" v-if="selected" @click.self="closeModal()">
      <div class="bg-black bg-opacity-75 p-6 rounded-md">
        <div class="flex items-center justify-between pb-4">
          <div class="flex items-center">
            <svg width="50" height="2" class="bg-yellow-600 rounded-sm"></svg>
            <h3 class="text-2xl font-bold">{{ selected.title }}</h3>
          </div>
          <font-awesome-icon class="text-gray-500 cursor-pointer" icon="times" @click="closeModal()" />
        </div>
        <div>
          <p class="text-sm" v-for="item in selected.items" :key="item.title">
            <strong>{{ item.title }}</strong>
            {{ item.content }}
          </p>
        </div>
        <div class="mt-4 flex flex-col items-center">
          <a class="text-blue-600 hover:text-blue-800" :href="whatsappUrl" target="__blank">
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2" />
            Solicitar información
          </a>
          <button class="mt-4 px-4 py-2 text-white bg-blue-600 rounded-md" @click="closeModal()">Volver</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SectionTitle from './SectionTitle.vue';
import { parseImageUrl } from '@/utils';

export default {
  name: 'HomeBenefits',
  props: {
    msg: String,
  },
  components: {
    FontAwesomeIcon,
    SectionTitle
  },
  data: function () {
    return {
      benefits: null,
      fetching: true,
      selected: null,
    };
  },
  mounted() {
    this.fetchBenefits();
  },
  computed: {
    whatsappUrl() {
      const whatsapp = this.$store.getters.association.whatsapp;
      return `https://api.whatsapp.com/send?phone=${whatsapp}`;
    },
  },
  methods: {
    getImageUrl(person) {
      return parseImageUrl(person); // Call the imported function
    },
    showMore(benefit) {
      this.selected = benefit;
    },
    closeModal() {
      this.selected = null;
    },
    fetchBenefits() {
      this.fetching = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/benefits`)
        .then((response) => {
          this.benefits = response.data;
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
