// civil_state.js

export const CIVIL_STATES = [
  {
    id: "S",
    name: "Soltero"
  },
  {
    id: "C",
    name: "Casado"
  },
  {
    id: "D",
    name: "Divorciado"
  },
  {
    id: "U",
    name: "Unión libre"
  },
  {
    id: "O",
    name: "Otro"
  },
  {
    id: "V",
    name: "Viudo"
  }
];
