<template>
  <div class="shadow p-3 mb-5 bg-white rounded">
    <img class="object-contain h-48 w-96 rounded" :src="image">
    <div class="py-2 font-bold">
      {{ title }}
    </div>
    <div class="py-1">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdministrativeCard',
  props: {
    title: String,
    subtitle: String,
    image: String,
    email: String,
  },
  methods: {
    hasImage(item) {
      return !!item;
    },
  },
};
</script>

<style scoped>
</style>
