<template>
  <body>
    <Header title="Becas Educativas" subtitle="Becas Educativas de Aso K-9 Internacional" image="benefits-header" />
    <div class="mt-4 container align-left">
      <p style="text-align: justify; padding: 20px;">
        ARTÍCULO 3:
        CONDICIONES PARA SER BENEFICIARIOS
      </p>
      <p style="text-align: justify; padding: 10px;">
        Serán sujetos para las becas:
      <ol style="text-align: justify; padding: 10px;">
        <li>a) Todos los asociados, que tengan un año o más de manera continua de pertenecer a la Asociación.</li>
        <li>b) Estar al día con las obligaciones contraídas con la Asociación.</li>
        <li>c) No contar con beca económica por parte de otra institución (solo se permitirá beca de avancemos), por lo que
          deberá firmar una declaración jurada de que no goza de un beneficio económico para estudios en otra institución.
        </li>
        <li>d) Que el salario bruto del asociado no supere los ¢450 000,00 (Cuatrocientos mil colones exactos) mensuales. El
          monto de salario bruto establecido en este artículo, será actualizado en enero y julio de cada año, tomando como
          parámetro los incrementos salariales decretados para el Sector Privado.</li>
        <li>e) El asociado no deberá contar con beca universitaria.</li>
        <li>f) El asociado deberá presentar una declaración jurada, cuando realice los trámites, indicando que no posee beca.
        </li>
        <li>g) El asociado solo podrá contar con beca, ya sea para bachillerado o licenciatura.</li>
        <li>h) El núcleo familiar solo podrá contar con máximo de una beca a la vez.</li>
        <li>i) Estudiante destacado nota mínima 90.</li>
      </ol>
      </p>
    </div>
  </body>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  name: 'BecasScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
  },
  data() {
    return {
      benefits: null,
      fetching: true,
      isModalActive: false,
      selected: null,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-data {
  margin-top: 70px;
  margin-bottom: 50px;
}

/* Tailwind CSS classes */

/* Par */
.sub-body-par {
  height: 550px;
  padding: 50px 20px 20px 20px;
  background: #f1f2f4;
}

.sub-body-par-image {
  height: 550px;
  padding: 0px;
  background: #f1f2f4;
}

.title-par {
  font-size: 28px;
  line-height: 33px;
  color: #21284b;
}

.text-par {
  font-size: 14px;
  line-height: 20px;
  color: #4f5571;
  height: 400px;
  overflow-y: auto;
}

/* Impar */
.body-impar-left {
  padding: 0px;
  text-align: left;
  border-color: transparent;
  background-color: transparent;
}

.body-impar-right {
  padding: 0px;
  text-align: left;
  background-color: #f1f2f4;
}

.is-primary-impar {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #202288 !important;
  background-color: transparent;
  border-color: transparent;
}

.title-impar {
  font-size: 28px;
  line-height: 33px;
  color: #21284b;
}

.text-impar {
  font-size: 14px;
  line-height: 20px;
  color: #4f5571;
  height: 400px;
  overflow-y: auto;
}

.icon-img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -o-object-fit: cover;
  object-fit: cover;
}

/* Tailwind CSS classes */
</style>
