<template>
  <nav class="nav">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="flex justify-between items-center py-6">

        <!-- Custom Logo -->

        <a href="/">
          <img src="../assets/asok9.svg" alt="Logo" class="h-20 lg:h-24 xl:h-28 my-2">
        </a>

        <!-- Navbar items -->
        <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
          <div class="relative" @mouseenter="showDropdown1 = true" @mouseleave="showDropdown1 = false">
            <a href="/" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-1': showDropdown1 }">Inicio</a>
          </div>

          <div class="relative" @mouseenter="showDropdown2 = true" @mouseleave="showDropdown2 = false">
            <a href="/beneficios" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-2': showDropdown2 }">Beneficios</a>
          </div>

          <!-- <div class="relative" @mouseenter="showDropdown3 = true" @mouseleave="showDropdown3 = false">
            <a href="/ayudas" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-2': showDropdown3 }">Ayudas</a>
          </div> -->

          <div class="relative" @mouseenter="showDropdown7 = true" @mouseleave="showDropdown7 = false">
            <a href="/afiliarse" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-7': showDropdown3 }">Afiliarse</a>
          </div>

          <div class="relative" @mouseenter="showDropdown4 = true" @mouseleave="showDropdown4 = false">
            <a href="/convenios" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-4': showDropdown4 }">Convenios</a>
          </div>

          <div class="relative" @mouseenter="showDropdown5 = true" @mouseleave="showDropdown5 = false">
            <a href="/nosotros" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-4': showDropdown5 }">Nosotros</a>
          </div>

          <div class="relative" @mouseenter="showDropdown7 = true" @mouseleave="showDropdown7 = false"
            v-if="catalogsActive">
            <a href="/catalogos" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-5': showDropdown7 }">Catálogos</a>
          </div>

          <div class="relative" @mouseenter="showDropdown8 = true" @mouseleave="showDropdown8 = false">
            <a href="/preguntas" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-2': showDropdown8 }">Preguntas Frecuentes</a>
          </div>

          <div class="relative" @mouseenter="showDropdown6 = true" @mouseleave="showDropdown6 = false">
            <a href="/contacto" class="px-4 py-2 rounded-md text-base text-lg font-medium"
              :class="{ 'text-hover-5': showDropdown6 }">Contacto</a>
          </div>

        </div>

        <!-- Mobile menu button -->
        <div class="-mr-2 flex items-center sm:hidden">
          <button @click="openMenu" type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-indigo-500">
            <span class="sr-only">Open main menu</span>
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div v-show="isOpen" class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <a href="/" class="text-base block px-3 py-2 rounded-md text-base font-medium">Home</a>
        <a href="/beneficios" class="text-base block px-3 py-2 rounded-md text-base font-medium">Beneficios</a>
        <a href="/convenios" class="text-base block px-3 py-2 rounded-md text-base font-medium">Asociados</a>
        <a href="/nosotros" class="text-base block px-3 py-2 rounded-md text-base font-medium">Acerca de</a>
        <a href="/ayudas" class="text-base block px-3 py-2 rounded-md text-base font-medium">Ayudas</a>
        <a href="/marchamo" class="text-base block px-3 py-2 rounded-md text-base font-medium">Marchamo</a>
        <a href="/catalogos" class="text-base block px-3 py-2 rounded-md text-base font-medium"
          v-if="catalogsActive">Catálogos</a>
        <a href="/preguntas" class="text-base block px-3 py-2 rounded-md text-base font-medium">Preguntas Frecuentes</a>
        <a href="/contacto" class="text-base block px-3 py-2 rounded-md text-base font-medium">Contacto</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  props: {
    association: Object,
  },
  watch: {
    association() {
      this.catalogsActive = this.association?.catalogs_active;
    },
  },
  data() {
    return {
      isOpen: false,
      showDropdown1: false,
      showDropdown2: false,
      showDropdown3: false,
      showDropdown4: false,
      showDropdown5: false,
      showDropdown6: false,
      showDropdown7: false,
      showDropdown8: false,
      catalogsActive: false,
    };
  },
  methods: {
    openMenu() {
      this.isOpen = !this.isOpekn;
    },
  },
};
</script>

<style scoped>
.text-hover-1 {
  color: #EBB859;
}

.text-hover-2 {
  color: #DA753D;
}

.text-hover-3 {
  color: #A34AFE;
}

.text-hover-4 {
  color: #50B2BF;
}

.text-hover-5 {
  color: #EC4897;
}

.text-hover-7 {
  color: #A34AFE;
}
</style>
