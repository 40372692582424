// gender.js

export const GENDERS = [
  {
    id: 1,
    name: "Masculino"
  },
  {
    id: 2,
    name: "Femenino"
  }
];
