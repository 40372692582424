<template>
  <section class="container mx-auto">
    <div class="flex flex-col p-6">
      <div class="flex header-asok9" v-if="agreement !== null">
        <div class="flex-1">
          <div class="bg-white rounded-lg text-left">
            <div class="p-4">
              <div class="mb-4" style="cursor: pointer;">
                <a class="text-left mb-2 pl-0" @click="goBack()" type="button">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  Volver
                </a>
              </div>
              <h2 class="text-lg font-semibold mb-2">{{ agreement.title }}</h2>
              <p class="text-sm">{{ agreement.content }}</p>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center h-60">
          <img :src="agreement.image" class="rounded-full h-60" />
        </div>
      </div>
      <div clas="mt-12" v-if="agreement.catalog_count > 0">
        <div class="text-left mb-8 mt-4">
          <h3 class="text-lg font-semibold mb-2">Catálogos</h3>
        </div>
        <Catalogs :agreement="agreement" />
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Catalogs from './Catalogs.vue';

export default {
  name: 'AgreementDetails',
  props: {
    agreement: Object,
  },
  components: {
    FontAwesomeIcon,
    Catalogs,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'Agreements',
      });
    },
  },
};
</script>
