<template>
  <body>
    <Header :title="title" :subtitle="subtitle" image="como-asociarse-header" />
    <div class="w-full">
      <div class="flex flex-col items-center">
        <div class="w-full md:w-3/4 xl:w-3/4 p-4">
          <SectionTitle :title="'Instrucciones'" />
          <div class="flex items-center justify-between mb-6">
            <p class="lefted">
              El siguiente formulario debe de ser llenado por el asociado (a) de forma completa.
              <br />
              La información suministrada debe ser real y quedará sujeta a comprobación por parte de ASO K-9
              <br />
              Adjuntar a este formulario los documentos que requieran para realizar el trámite
            </p>
          </div>
          <AffiliateForm />
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import Header from '../components/Header.vue';
import SectionTitle from '../components/SectionTitle.vue';
import AffiliateForm from "@/components/AffiliateForm.vue";

export default {
  name: 'AffiliateScreen',
  components: {
    Header,
    SectionTitle,
    AffiliateForm,
  },
  data() {
    return {
      title: 'Afiliación',
      subtitle: 'Afiliate a ASO K-9 y obtené beneficios exclusivos',
      isModalActive: false,
      isConfirmationActive: false,
      loading: false,
    };
  },
};
</script>

<style scoped>
.lefted {
  text-align: left;
}
</style>
