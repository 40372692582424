<template>
  <div>
    <Header :title="title" :subtitle="subtitle" image="agreements-header" />

    <div class="flex flex-col items-center mt-10">
      <div class="w-full md:w-3/4 xl:w-3/4">
        <SectionTitle :title="'Información de contacto'" />
        <div class="flex-row mx-auto px-4">
          <div class="flex justify-start mb-4">
            <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div class="p-3 mb-5 bg-white rounded">
                <div class="flex items-center">
                  <div class="text-left mb-5">
                    <span class="mr-2">
                      <font-awesome-icon icon="mobile-phone" />
                    </span>
                    Whatsapp: <a href="http://wa.me/+50688615479" target="__blank">8861-5479
                    </a>
                  </div>
                </div>
                <div class="flex items-center">
                  <a class="bg-green-500 text-white py-2 px-4 rounded-md shadow-lg hover:bg-green-600" type="button"
                    href="http://wa.me/+50688615479" target="__blank">
                    <span>Contactar a ASO K-9 por Whatsapp</span>
                  </a>
                </div>
              </div>

              <div class="p-3 mb-5 bg-white rounded">
                <div class="flex items-center">
                  <div class="text-left mb-5">
                    <span class="mr-2">
                      <font-awesome-icon icon="phone" />
                    </span>
                    Número de teléfono: <a href="tel:+50640014611">4001-4611</a>
                  </div>
                </div>
                <div class="flex items-center">
                  <a class="bg-green-500 text-white py-2 px-4 rounded-md shadow-lg hover:bg-green-600" type="button"
                    href="tel:+50640014611">
                    <span>Llamar a Teléfono Fijo Aso K-9</span>
                  </a>
                </div>
              </div>

              <div class="p-3 mb-5 bg-white rounded">
                <div class="flex items-center">
                  <div class="text-left mb-5">
                    <span class="mr-2">
                      <font-awesome-icon icon="envelope" />
                    </span>
                    Correo electrónico: <a href="mailto:asok-9@k-9internacional.com">asok-9@k-9internacional.com</a>
                  </div>
                </div>
                <div class="flex items-center">
                  <button class="bg-blue-500 text-white py-2 px-4 rounded-md shadow-lg hover:bg-blue-600" type="button">
                    <span>Enviar correo electrónico</span>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="flex-row mx-auto px-4">
          <div class="flex justify-start mb-4">
            <AsoMap />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SectionTitle from '../components/SectionTitle.vue';
import AsoMap from '../components/AsoMap.vue'

export default {
  name: 'ContactScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
    SectionTitle,
    FontAwesomeIcon,
    AsoMap,
  },
  data() {
    return {
      title: 'Contacto',
      subtitle: 'Nuestra información para cualquier tema que necesites ver con Aso K-9',
      inquiries: ["Solicitud de crédito", "Consulta", "Otros"],
      fetching: true,
      selected: null,
      submitting: false,
      isModalActive: false,
      alert: {
        title: '',
        detail: '',
      },
      form: {
        name: '',
        email: '',
        msg: '',
      }
    };
  },
  mounted() {
  },
  methods: {
    isValidForm() {
      return this.form.name != '' && this.form.email != '' && this.form.msg != '';
    },
    sendMail() {
    }
  },
};
</script>

<style scoped>
/* Add your custom styles here */
.bg-button {
  background: #50B1BE;
}
</style>
