<template>

  <div>
    <div class="flex flex-col items-center">
      <div class="w-full md:w-3/4 xl:w-3/4">
        <div v-if="fetching">Loading...</div>
        <div v-else>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div class="bg-white shadow p-4 rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300"
              v-for="(catalog, index) in catalogs" :key="'key_agreement_card' + index">
              <img v-if="!agreement" class="w-full h-48 object-contain rounded-md" :src="getImageUrl(catalog.agreement)"
                :alt="catalog.agreement.name" />
              <p class="text-sm text-gray-600" v-if="!agreement">Proveedor: {{ catalog.agreement.title }}</p>
              <h3 class="text-lg font-semibold text-gray-800 mb-2">{{ catalog.title }}</h3>
              <p class="text-sm text-gray-600">{{ catalog.content }}</p>

              <button
                class="flex items-center justify-center bg-green-600 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md w-full mt-4"
                @click="requestCatalog(catalog)">
                <span class="mr-6">
                  Solicitar vía whatsapp
                </span>
                <font-awesome-icon :icon="['fas', 'phone']" />
              </button>
              <button v-if="catalog.link_url"
                class="flex items-center justify-center bg-blue-600 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md w-full mt-4"
                @click="openCatalog(catalog.link_url)">
                <span class="mr-6">
                  Ver Catálogo Online
                </span>
                <font-awesome-icon :icon="['fas', 'download']" />
              </button>
              <button v-if="catalog.file"
                class="flex items-center justify-center bg-blue-600 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md w-full mt-4"
                @click="openCatalog(catalog.file)">
                <span class="mr-6">
                  Descargar
                </span>
                <font-awesome-icon :icon="['fas', 'download']" />
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { parseImageUrl } from '@/utils';

export default {
  name: 'CatalogObjs',
  props: {
    msg: String,
    agreement: Object,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      fetching: true,
      catalogs: [],
    };
  },
  mounted() {
    this.fetchCatalogs();
  },
  methods: {
    getImageUrl(agreement) {
      return parseImageUrl(agreement);
    },
    openCatalog(url) {
      window.open(url, '_blank');
    },
    requestCatalog(catalog) {
      const link = 'https://wa.me/+50688615479?text=Hola,%20me%20interesa%20el%20catálogo%20de%20' + catalog.title + 'de%20' + catalog.agreement.title
      window.open(link, '_blank');
    },
    fetchCatalogs() {
      this.fetching = true;
      const baseUrl = `${process.env.VUE_APP_SERVER_ENDPOINT}/catalogs`;
      const url = this.agreement ? `${baseUrl}?agreement_id=${this.agreement.id}` : baseUrl;
      axios
        .get(url)
        .then((response) => {
          this.catalogs = response.data;
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
