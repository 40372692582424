<template>
  <div>
    <Header title="Acerca de Aso K-9" subtitle="Junta Directiva y Personal Administrativo" image="agreements-header" />
    <section class="px-12 mt-10">
      <SectionTitle title="Junta Directiva" />
      <AdminStaff :directive="true"/>
    </section>
    <section class="px-12 mt-10">
      <SectionTitle title="Personal Administrativo" />
      <AdminStaff :directive="false"/>
    </section>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import AdminStaff from '../components/AdminStaff.vue';
import SectionTitle from '../components/SectionTitle.vue';
import axios from 'axios';

export default {
  name: 'AboutUsScreen',
  props: {
    msg: String,
  },
  components: {
    Header,
    SectionTitle,
    AdminStaff,
  },
  data() {
    return {
      fetching: true,
      isModalActive: false,
      selected: null,
      admins: [],
      directive: [],
    };
  },
  mounted() {
    this.fetchAdministratives();
    this.fetchDirective();
  },
  methods: {
    fetchDirective() {
      this.fetching = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/administratives?directive=true`
        )
        .then((response) => {
          this.directive = response.data;
          console.log(this.directive.length)
        })
        .catch(() => {})
        .finally(() => {
          this.fetching = false;
        });
    },
    fetchAdministratives() {
      this.fetching = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/administratives?directive=false`
        )
        .then((response) => {
          this.admins = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
