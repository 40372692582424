<template>
  <footer>
    <div class="footer-content">
      <p>Creado con ♥ por <a href="http://www.kougarlogic.com">Kougar Logic</a>
        © 2023. Todos los derechos reservados.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
footer {
  margin-bottom: 20px;
  padding: 20px;
  text-align: center;
}

.footer-content {
  font-size: 14px;
  color: black;
}

.footer-content p {
  margin: 5px 0;
}

.footer-content p a {
  color: black;
}
</style>
