<template>
  <div>
    <div class="flex flex-col items-center">
      <div class="w-full md:w-3/4 xl:w-3/4">
        <SectionTitle :title="'Convenios'" />

        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center justify-center mb-4">
            <span class="text-black px-4 py-2 rounded-md mb-2 sm:mb-0 sm:mr-2">Filtros por categoría:</span>
            <a :class="allActive ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-700'" @click="activateAll()"
              class="py-2 px-4 rounded-md cursor-pointer transition-colors duration-300 mb-2 sm:mb-0">
              Todas
            </a>
            <a :class="filter.active ? 'bg-orange-500 text-white mt-4' : 'bg-gray-200 text-gray-700 mt-4'"
              @click="activateFilter(filter)" v-for="(filter, index) in filters" :key="'key_agreement_filter' + index"
              class="py-2 px-4 rounded-md cursor-pointer transition-colors duration-300 mb-2 sm:mb-0 sm:ml-2">
              {{ filter.name }}
            </a>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div class="bg-white shadow p-4 rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300"
              v-for="(agreement, index) in agreementsFiltered" :key="'key_agreement_card' + index"
              @click="goTo(agreement)">
              <div class="mb-4">
                <img class="w-full h-48 object-contain rounded-md" :src="getImageUrl(agreement)"
                  :alt="agreement.name" />
              </div>
              <h3 class="text-lg font-semibold text-gray-800 mb-2">{{ agreement.title }}</h3>
              <p class="text-sm text-gray-600">{{ agreement.content }}</p>
              <div class="mt-4">
                <span class="bg-teal-100 text-teal-600 text-sm px-2 py-1 rounded-md" v-if="agreement.category !== null">
                  {{ agreement.category.name }}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SectionTitle from './SectionTitle.vue';
import { parseImageUrl } from '@/utils';

export default {
  name: 'AgreementsHome',
  props: {
    msg: String,
  },
  components: {
    SectionTitle
  },
  data() {
    return {
      agreements: null,
      fetching: true,
      allActive: true,
      isModalActive: false,
      selected: null,
      agreementsFiltered: null,
      filters: [],
      filtersActive: [],
    };
  },
  mounted() {
    this.fetchAgreements();
  },
  methods: {
    getImageUrl(person) {
      return parseImageUrl(person); // Call the imported function
    },
    goTo(agreement) {
      this.$router.push({
        name: 'AgreementDetails',
        query: { id: agreement.id },
      });
      // Iterate over filters
      for (const obj of this.filtersActive) {
        obj.active = false;
      }
    },
    activateAll() {
      this.allActive = true;
      this.fetchAgreements();
    },
    activateFilter(filter) {
      filter.active = !filter.active;
      if (filter.active) {
        this.filtersActive.push(filter.id);
      } else {
        this.filtersActive = this.filtersActive.filter((o) => {
          return o !== filter.id;
        });
      }
      // Reset all if there are no filters active
      this.allActive = !this.filtersActive.length > 0
      const filterParameter = this.filtersActive.join();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_ENDPOINT}/agreements?categories=${filterParameter}&limit=100`
        )
        .then((response) => {
          this.agreementsFiltered = response.data;
        })
        .catch(() => { });
    },
    fetchAgreements() {
      this.fetching = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/agreementCategories`)
        .then((response) => {
          this.filters = response.data.map((o) => {
            return { ...o, active: false };
          });
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/agreements?limit=100`)
        .then((response) => {
          this.agreements = response.data;
          this.agreementsFiltered = this.agreements;
        })
        .catch(() => { })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
