<template>

  <body>
    <Header :title="title" :subtitle="subtitle" image="como-asociarse-header" />
    <div class="w-full">
      <div class="flex flex-col items-center">
        <div class="w-full md:w-3/4 xl:w-3/4 p-4">
          <SectionTitle :title="'Catálogos'" />
          <div class="flex items-center justify-between mb-6">
            <p class="lefted">
              ASO K-9 Internacional, pone a disposición de sus asociados y público en general, los siguientes catálogos:
            </p>
          </div>
          <Catalogs />
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import Header from '../components/Header.vue';
import SectionTitle from '../components/SectionTitle.vue';
import Catalogs from '../components/Catalogs.vue';

export default {
  name: 'CatalogScreen',
  components: {
    Header,
    SectionTitle,
    Catalogs,
  },
  data() {
    return {
      title: 'Catálogos',
      subtitle: 'Disfrutá de los catálogos de ASO K-9',
      isModalActive: false,
      isConfirmationActive: false,
      loading: false,
    };
  },
};
</script>

<style scoped>
.lefted {
  text-align: left;
}
</style>
