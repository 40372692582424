// relationship.js

export const RELATIONSHIPS = [
  {
    id: 1,
    name: "Padre"
  },
  {
    id: 2,
    name: "Hijo"
  },
  {
    id: 3,
    name: "Hermano"
  },
  {
    id: 4,
    name: "Primo"
  },
  {
    id: 5,
    name: "Abuelo"
  },
  {
    id: 6,
    name: "Tío"
  },
  {
    id: 7,
    name: "Nieto"
  }
];
