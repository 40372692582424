import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faEnvelope,
  faEye,
  faGlobe,
  faHandsHelping,
  faMobilePhone,
  faMobileScreen,
  faPhone,
  faRocket,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { createApp } from "vue";
import VueGoogleMaps from "vue-google-maps-community-fork";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import routes from "./routes";
import { store } from "./store";
import "./style.css";

library.add(
  faChevronRight,
  faChevronLeft,
  faDownload,
  faEnvelope,
  faEye,
  faGlobe,
  faHandsHelping,
  faMobilePhone,
  faMobileScreen,
  faPhone,
  faRocket,
  faTimes
);

const app = createApp(App);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
  },
});

// Add the router
app.use(router);

// Install the store instance as a plugin
app.use(store);

app.mount("#app");
