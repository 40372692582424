<template>
  <Navbar :association="association" />
  <div id="main-frame" v-if="loading">
    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Cargando...</span>
      </div>
    </div>
  </div>
  <router-view v-if="!loading" />
  <Footer />
</template>

<script>
import axios from "axios";
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  mounted() {
    this.fetchAssociationData();
  },
  data() {
    return {
      loading: false,
      association: null,
    };
  },
  methods: {
    fetchAssociationData() {
      axios
        .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/association/1`)
        .then((response) => {
          this.association = response.data;
        })
        .catch(() => { })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    Navbar,
    Footer,
  }
}
</script>

<style>
@import '@/assets/main.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
