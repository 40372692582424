<template>
  <body>
    <Header title="Ayudas Solidarias ASO K-9"
      subtitle="Formulario para optar por las Ayudas Solidarias brindadas por ASO K-9" image="helps-header" />
    <div class="container">
      <div id="loader">
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center">
        <div class="w-full md:w-3/4 xl:w-3/4 p-4">
          <SectionTitle :title="'Instrucciones'" />
          <div class="flex items-center justify-between mb-6">
            <p class="lefted">
              El siguiente formulario debe de ser llenado por el asociado (a) de forma completa.
              <br />
              La información suministrada debe ser real y quedará sujeta a comprobación por parte de ASO K-9
              <br />
              Adjuntar a este formulario los documentos que requieran para realizar el trámite
            </p>
          </div>
          <HelpRequestForm />
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import Header from "@/components/Header.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import HelpRequestForm from "@/components/HelpRequestForm.vue";

export default {
  name: "HelpRequestScreen",
  components: {
    Header,
    SectionTitle,
    HelpRequestForm,
  },
};
</script>

<style scoped>
.lefted {
  text-align: left;
}
</style>
